import { analytics } from './endpoints/analytics';
import { brands } from './endpoints/brands';
import { orderPlatforms } from './endpoints/orderPlatforms';
import { posPlatforms } from './endpoints/posPlaforms';
import { reports } from './endpoints/reports';
import { stores } from './endpoints/stores';

export const OrderlyManagerApi = {
  analytics,
  brands,
  orderPlatforms,
  posPlatforms,
  reports,
  stores
};
