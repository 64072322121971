import { ApiRequest } from '../../../../../core';
import { base } from '../../../../base';

type Params = {
  storeId: string;
  brandId: string;
}

export const create = (platformType: string, data: Params): ApiRequest<void> =>
  ({ ...base(), method: 'POST', url: `/order-platforms/${platformType}/items/refresh`, data });
