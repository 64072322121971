import { useRouter } from 'next/router';
import { PlatformType } from '../../entities';

export const usePlatformTypeQuery = (): PlatformType[] => {
  const router = useRouter();
  const { boteats, demaecan, demaecanOfficial, menuOfficial, sukesan, ubereatsOfficial, woltOfficial } = router.query;

  return queryablePlatformTypes({
    includesBotEats: !!boteats,
    includesDemaecan: !!demaecan,
    includesDemaecanOfficial: !!demaecanOfficial,
    includesMenuOfficial: !!menuOfficial,
    includesSukesan: !!sukesan,
    includesUbereatsOfficial: !!ubereatsOfficial,
    includesWoltOfficial: !!woltOfficial
  });
};

const queryablePlatformTypes = (params: {
  includesBotEats?: boolean,
  includesDemaecan?: boolean,
  includesDemaecanOfficial?: boolean,
  includesMenuOfficial?: boolean,
  includesSukesan?: boolean,
  includesUbereatsOfficial?: boolean,
  includesWoltOfficial?: boolean
}): PlatformType[] => [
  params.includesBotEats ? 'boteats' : undefined,
  params.includesDemaecan ? 'demaecan' : undefined,
  params.includesDemaecanOfficial ? 'demaecanOfficial' : undefined,
  params.includesMenuOfficial ? 'menuOfficial' : undefined,
  params.includesSukesan ? 'sukesan' : undefined,
  params.includesUbereatsOfficial ? 'ubereatsOfficial' : undefined,
  params.includesWoltOfficial ? 'woltOfficial' : undefined,
]
  .filter((value): value is PlatformType => !!value);
