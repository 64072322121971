import { DeliveryMethod, PlatformType } from '../../../../../entities';
import { ApiRequest } from '../../../../core';
import { base } from '../../../base';

type Params = {
  storeId: string;
  brandId: string;
  deliveryMethod?: DeliveryMethod;
  pickupPosItemEnabled?: boolean;
}

export const update = (platformType: PlatformType, data: Params): ApiRequest<void> =>
  ({ ...base(), method: 'PATCH', url: `/order-platforms/${platformType}/setting`, data });
