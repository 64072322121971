import { IpadicFeatures, Tokenizer } from 'kuromoji';
import { useEffect, useState } from 'react';
import {
  calculateAllSimilarity,
  Item,
  itemToSimilarityComparable,
  PosItem,
  posItemToSimilarityComparable,
  Similarity
} from '../entities';

interface Params {
  items: Item[] | undefined
  posItems: PosItem[] | undefined
  tokenizer: Tokenizer<IpadicFeatures> | undefined;
}

type Response = {
  similarityMap: Record<string, Similarity> | undefined
  comparablesLoading: boolean
}

export const useItemPosItemComparables = ({ items, posItems, tokenizer }: Params): Response => {
  const [comparablesLoading, setIsComparablesLoading] = useState<boolean>(false);
  const [similarityMap, setSimilarityMap] = useState<Record<string, Similarity> | undefined>(undefined)

  useEffect(() => {
    if(!tokenizer || !items || !posItems) return

    setIsComparablesLoading(true);

    Promise.resolve()
      .then(() =>
        calculateAllSimilarity(
          items.map(item => itemToSimilarityComparable(item, tokenizer)),
          posItems.map(posItem => posItemToSimilarityComparable(posItem, tokenizer))
        )
          .reduce((acc, item) => Object.assign(acc, { [item.id]: item }), {}))
      .then(setSimilarityMap)
      .finally(() => setIsComparablesLoading(false))
  }, [items, posItems, tokenizer])

  return { similarityMap, comparablesLoading }
}
