import { useEffect, useState } from 'react';
import { ApiRequest, createApiClient } from '../apis/core';
import { useAuthState } from './useAuthState';

type Response<T> = [T | undefined, boolean, Error | undefined];

export const useResourceHttpApi = <T>(request: ApiRequest<T> | undefined): Response<T> => {
  const [auth, authLoading] = useAuthState();

  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!auth || authLoading) return;

    if (!request) return;

    setLoading(true);

    auth.getIdToken()
      .then(token => ({ ...request, headers: { ...request.headers, Authorization: `Bearer ${token}` } }) as ApiRequest<T>)
      .then(createApiClient().request)
      .then(data => { setData(data); })
      .catch(error => { setError(error); })
      .finally(() => { setLoading(false) });
  }, [request, auth, authLoading]);

  return [data, loading, error];
}
