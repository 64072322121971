import { HasRawPassword, HasTimestamp, OrderPlatformIdentity, PlatformType } from '../../../../../entities';
import { ApiRequest } from '../../../../core';
import { base } from '../../../base';

type Params = {
  type: 'registration' | 'activation' | 'deactivation' | 'deletion';
  storeId: string;
  brandId: string;
  executeTime: string;
  identities?: Omit<OrderPlatformIdentity<HasRawPassword>, 'id' | 'accountId' | 'invalidatedTime' | keyof HasTimestamp>[];
}

export const create = (platformType: PlatformType, data: Params): ApiRequest<void> =>
  ({ ...base(), method: 'POST', url: `/order-platforms/${platformType}/operations`, data });
