import { pickObject } from '../utils';
import { HasTimestamp } from './core/HasTimestamp';
import { PosPlatformType } from './PosPlatformType';
import { StoreRelation } from './Store';

export type PosReference = StoreRelation & { type: PosPlatformType };
export type PosRelation = StoreRelation & { posType: PosPlatformType };

export interface PosPlatformCommon<T extends PosPlatformType> extends PosReference, HasTimestamp {
  id: T
  type: T

  sequenceNumber?: number
}

export type LinktoPlatform = PosPlatformCommon<'linkto'> & { contractCode: string };
export type StessoPlatform = PosPlatformCommon<'stesso'>;
export type OrderLinkagePlatform = PosPlatformCommon<'ngp'> & { businessCode: string, storeCode: string }

export type PosPlatform =
  | LinktoPlatform
  | StessoPlatform
  | OrderLinkagePlatform


export const posPlatformToReference = (pos: PosPlatform): PosReference =>
  pickObject(pos, ['accountId', 'storeId', 'type']);

export const posPlatformToRelation = (pos: PosPlatform): PosRelation =>
  ({ ...pickObject(pos, ['accountId', 'storeId']), posType: pos.type  });
