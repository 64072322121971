import { Brand } from '../../../../entities';
import { ApiRequest } from '../../../core';
import { base } from '../../base';

type Params = {
  name: string;
}

type Response = {
  brand: Pick<Brand, 'id'>
}

export const create = (data: Params): ApiRequest<Response> =>
  ({ ...base(), method: 'POST', url: '/brands', data });
