import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { PosItem, PosPlatformType } from '../../entities';

export const usePosItems = (
  accountId: string | undefined,
  storeId: string | undefined,
  posType: PosPlatformType | undefined
): [PosItem[] | undefined, boolean, Error | undefined] => {
  const createQuery = () => {
    if (accountId == undefined || posType === undefined) {
      return undefined;
    }

    return firebase
      .firestore()
      .collection('accounts')
      .doc(accountId)
      .collection('stores')
      .doc(storeId)
      .collection('pos')
      .doc(posType)
      .collection('posItems');
  };

  return useCollectionData<PosItem>(createQuery());
};
