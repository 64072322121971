import {
  HasRawPassword,
  HasTimestamp,
  PosItem,
  PosPlatformIdentity,
  PosPlatformType,
  PosRelation
} from '../../../../../entities';
import { ApiRequest } from '../../../../core';
import { base } from '../../../base';

type Params = {
  storeId: string;
  filePath?: string;
  identity?: Omit<PosPlatformIdentity<HasRawPassword>, 'id' | 'accountId' | 'invalidatedTime' | keyof HasTimestamp>;
}

type Response = Omit<PosItem, keyof HasTimestamp | keyof PosRelation>[];

export const list = (posPlatformType: PosPlatformType, data: Params): ApiRequest<Response> =>
  ({ ...base(), method: 'POST', url: `/pos-platforms/${posPlatformType}/items`, data });
