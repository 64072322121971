export const PlatformTypes = [
  'boteats',
  'demaecan',
  'demaecanOfficial',
  'menu',
  'menuOfficial',
  'sukesan',
  'ubereats',
  'ubereatsOfficial',
  'wolt',
  'woltOfficial',
] as const;

export type PlatformType = typeof PlatformTypes[number]

export const platformTypesToShow = (integratedPlatformTypes: PlatformType[], queriedPlatformTypes: PlatformType[]): PlatformType[] =>
  PlatformTypes
    .filter(platformType =>
      integratedPlatformTypes.includes(platformType)
      || canShow(platformType as PlatformType, integratedPlatformTypes)
      || queriedPlatformTypes.includes(platformType)
    )
    .sort(sortPlatformType)

export const integratablePlatformTypes = [
  'menu' as const,
  'ubereats' as const
];

// TODO: Use Integration from our API
export const sortPlatformType = (a: PlatformType, b: PlatformType): number =>
  priority[a] - priority[b]

const priority = {
  ubereats: 10,
  ubereatsOfficial: 11,
  demaecan: 20,
  demaecanOfficial: 21,
  menu: 30,
  menuOfficial: 31,
  wolt: 40,
  woltOfficial: 41,
  sukesan: 999,
  boteats: 999
} as const;

const canShow = (platformType: PlatformType, integratedPlatformTypes: PlatformType[]) => {
  switch(platformType) {
    case 'boteats':
      return false;
    case 'demaecan':
      return !integratedPlatformTypes.some(type => 'demaecanOfficial' === type)
    case 'demaecanOfficial':
      return false;
    case 'menu':
      return !integratedPlatformTypes.some(type => 'menuOfficial' === type)
    case 'menuOfficial':
      return false;
    case 'sukesan':
      return false;
    case 'ubereats':
      return !integratedPlatformTypes.some(type => 'ubereatsOfficial' === type);
    case 'ubereatsOfficial':
      return false;
    case 'wolt':
      return !integratedPlatformTypes.some(type => 'woltOfficial' === type)
    case 'woltOfficial':
      return false;
  }
};
