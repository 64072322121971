import firebase from 'firebase';
import { pickObject } from '../utils';
import { Address } from './Address';
import { HasId } from './core/HasId';
import { HasTimestamp } from './core/HasTimestamp';
import { PlatformRelation } from './Platform';
import { PlatformType } from './PlatformType';

export type Fulfillment = 'PICKUP' | 'DELIVERY';

export type OrderStatus = typeof orderStatuses[number];

export const orderStatuses = [
  'CREATED',
  'ACCEPTED',
  'DENIED',
  'READY',
  'COMPLETED',
  'CANCELED',
  'ITEM_CHANGE_REQUESTED',
  'UNKNOWN'
] as const;

const orderStatusOrder = {
  CREATED: 1,
  ACCEPTED: 2,
  READY: 3,
  COMPLETED: 4,
  DENIED: 999,
  CANCELED: 999,
  ITEM_CHANGE_REQUESTED: 999,
  UNKNOWN: 999
} as const

export const sortOrderStatuses = (a: OrderStatus, b: OrderStatus): number =>
  orderStatusOrder[b] - orderStatusOrder[a]

export type CanceledReason =
  | 'customer_canceled'
  | 'store_canceled'
  | 'driver_canceled'
  | 'platform_canceled'
  | 'unknown';

export type StatusReason = CanceledReason;

export enum Interaction {
  LEAVE_AT_DOOR = 'leave_at_door',
  DOOR_TO_DOOR = 'door_to_door',
  CURBSIDE = 'curbside',
}

// TODO: Migrate property name to platformType and remove this?
type OrderPlatformRelation = Omit<PlatformRelation<PlatformType>, 'platformType'>
  & { platform: PlatformRelation<PlatformType>['platformType'] };

export type OrderReference = HasId & OrderPlatformRelation;

export type OrderRelation = { orderId: OrderReference['id'] } & OrderPlatformRelation;

export interface Order extends OrderReference, HasTimestamp {
  uuid: string;
  displayId: string;

  // ---------------------------------------------------
  // Status
  status: OrderStatus;
  statusReason: StatusReason | null;
  rawStatusReason: string | null;
  statuses: {
    caller: string;
    status: OrderStatus;
    statusReason: StatusReason | null;
    rawStatusReason: string | null;
    updatedAt: firebase.firestore.Timestamp;
  }[] | null;

  // ---------------------------------------------------
  // Order content
  comment: string;
  cutlery: boolean | null;
  customer: OrderCustomer;

  items: OrderItem[];
  price: OrderPrice;

  // ---------------------------------------------------
  // How to deliver

  /**
   * @deprecated
   * Use deliveryMethod instead
   */
  fulfillment: Fulfillment;

  deliveryMethod: 'eat_in' | 'pick_up' | 'delivery'

   /**
   * @deprecated
   * ToDo: Use Delivery properties instead.
   */
  delivery: {
    name: string;
    pictureUrl?: string;
    phoneNumber?: string;
  } | null;

   /**
   * @deprecated
   * ToDo: Use Delivery properties instead.
   */
  driverRequesting: boolean | null;

  inHouseDelivery: {
    /**
     * @deprecated Use Address properties
     */
    title: string;

    address: string;

    comment: string | null;
    interaction: Interaction;
    cash: number | null;
  } & Address | null;

  // ---------------------------------------------------
  // POS
  // TODO: Remove from Order
  /**
   * @deprecated
   */
  pos: {
    tableNo: string;
    error: boolean;
  } | null;

  // ---------------------------------------------------
  deliveryTimeType:
    | { type: 'immediate' }
    | { type: 'scheduled', time: firebase.firestore.Timestamp | undefined }

  /**
   * @deprecated
   * TODO: Use deliveryTimeType
   */
  scheduled: boolean;

  /**
   * @deprecated
   * TODO: This needs to be a Status
   */
  scheduledAccepted: boolean;

  // ---------------------------------------------------
  // Times
  // TODO: Refactor
  //   - Needs both of Expected and Actual time?
  //   - Split type definition by deliveryMethod
  //      - Ex: If the delivery method is pickup, deliveryTime doesn't make any sense
  //      - Ex: If the delivery method is inhouse, preparationTime / pickupTime doesn't make any sense
  //   - Use same word for 'READY' ( should correspond to preparationTime
  preparationDelayed: boolean;
  preparationTime: firebase.firestore.Timestamp;
  /**
   * @deprecated
   * ToDo: Use DeliveryEstimation properties instead.
   */
  pickupTime: firebase.firestore.Timestamp;
  /**
   * @deprecated
   * ToDo: Use DeliveryEstimation properties instead.
   */
  deliveryTime: firebase.firestore.Timestamp | null;
}

export interface OrderCustomer {
  name: string;
  phoneNumber: string;
  uuid: string | null;
  phonePinCode: string | null;
  orderCount: number | null;
}

export interface OrderItem {
  /**
   * Item ID generated by / stored in Platforms
   * TODO: Rename this like externalId
   */
  uuid: string | null;

  /**
   * POS ID set by / stored in Platforms
   */
  externalPosId: string | null;
  externalPosTitle: string | null;

  itemChangeRequestId: string | null;
  title: string;
  price: number;
  quantity: number;
  comment: string | null;
  allergens: {
    name: string;
  }[] | null;
  options: OrderOption[];
}

export interface OrderOption {
  /**
   * Option selection ID
   * This must be different if the order has same uuid options but different selection.
   * e.g.
   * - Order
   *     - Item(uuid: 1, name: そば)
   *         - Option(id: 1, uuid: 1, name: かき揚げ, quantity: 3)
   *     - Item(uuid: 1, name: そば)
   *         - Option(id: 2, uuid: 1, name: かき揚げ, quantity: 1)
   *         - Option(id: 3, uuid: 1, name: かき揚げ, quantity: 2)
   * TODO: Rename this to selectionId or refactor model to OrderOption <- OrderOptionSelection -> OptionCategory
   */
  id: string | null;

  /**
   * Item ID generated by / stored in Platforms
   * TODO: Rename this like externalId
   */
  uuid: string | null;

  /**
   * Parent option selection ID
   * TODO: Rename this like parentOptionSelectionId
   */
  parentId: string | null;

  /**
   * POS ID set by / stored in Platforms
   */
  externalPosId: string | null;
  externalPosTitle: string | null;

  title: string;
  category: string | null;
  comment: string | null;
  price: number | null;
  quantity: number;
}

export type OrderItemOrOption = OrderItem | OrderOption

export interface OrderPrice {
  subtotal: number;                    // 小計            / sum(Item.price) + sum(Option.price) 配送料金を含まず
  total: number;                       // 合計            / subtotal + sum(promotions) + adjustment
  promotion: number | null;            // @deprecated    / sum(promotions)
  promotions: number[] | null;         // 割引金額         / 負数
  adjustment: number | null;           // 調整額           / 現状 Uber のみに項目ありだが、未使用？
  delivery: number | null;             // 配送料金         / deliveryPromotion 適用前の金額。 total, subtotal とは関連なし
  deliveryPromotions: number[] | null; // 配送料金への割引
}

export const interactionText = (interaction: Interaction): string =>
  ({
    [Interaction.LEAVE_AT_DOOR]: '玄関先に置く',
    [Interaction.DOOR_TO_DOOR]: '玄関先で受け取る',
    [Interaction.CURBSIDE]: '外で受け取る'
  })[interaction];


export const orderToReference = (order: Order): OrderReference =>
  pickObject(order, ['id', 'accountId', 'storeId', 'brandId', 'platform']);

export const orderToRelation = (order: Pick<Order, keyof OrderReference>): OrderRelation => ({
  ...pickObject(order, ['accountId', 'storeId', 'brandId', 'platform']),
  orderId: order.id
});

export const calculateOrderItemPriceTotal = (orderItem: OrderItem): number =>
  orderItem.price + orderItem.options.reduce((acc, elem) => {
    const price = elem.price ?? 0;
    return acc + price
  }, 0)
