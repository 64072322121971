import firebase from 'firebase';
import { Store } from '../../entities';
import { MutationResult, useMutation } from '../../utils';

export const useStoreMutation = (): MutationResult<Store> =>
  useMutation((store) =>
    firebase
      .firestore()
      .collection('accounts')
      .doc(store.accountId)
      .collection('stores')
      .doc(store.id),
  );
