import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Item, PlatformType } from '../../entities';

export const useItems = (
  accountId: string | undefined,
  storeId: string | undefined,
  brandId: string | undefined,
  platformType: PlatformType | undefined
): [Item[] | undefined, boolean, Error | undefined] => {
  const createQuery = () => {
    if (!accountId || !storeId) return undefined;

    let query = firebase
      .firestore()
      .collectionGroup('items')
      .where('archived', '==', false)
      .where('accountId', '==', accountId)
      .where('storeId', '==', storeId);

    if (brandId)
      query = query.where('brandId', '==', brandId);

    if (platformType)
      query = query.where('platformType', '==', platformType);

    return query
  }

  return useCollectionData<Item>(createQuery());
}
