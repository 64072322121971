import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { OrderlyManagerApi } from '../apis/orderlyManagerApi';
import {
  BotEatsIdentity,
  Brand,
  DemaecanIdentity,
  DemaecanOfficialIdentity,
  HasRawPassword,
  HasTimestamp,
  Item,
  MenuIdentity,
  OrderPlatformIdentity,
  PlatformOperationType,
  Store,
  SukesanIdentity,
  UberEatsIdentity,
  UberEatsOfficialIdentity,
  WoltIdentity,
  WoltOfficialIdentity
} from '../entities';
import { useAppContext, useMutationHttpApi } from '../hooks';
import { PlatformFormData } from '../partials';

export enum RegistrationStep {
  STORE = '店舗登録',
  BRAND = 'ブランド登録',
  PLATFORM = 'プラットフォーム連携',
  MENU = 'メニュー確認',
}

type OperationTypes = 'STORE' | 'BRAND' | 'PLATFORM';
type StoreParams = Pick<Store, 'name' | 'email'>;
type BrandParams = { id: string | undefined, name: string };

type IdentityParam = Omit<OrderPlatformIdentity<HasRawPassword>, 'id' | 'accountId' | 'invalidatedTime' | keyof HasTimestamp>;

type Result = {
  allSteps: RegistrationStep[]
  step: RegistrationStep
  store: StoreParams | undefined
  brand: BrandParams | undefined
  menu: Omit<Item, 'accountId' | 'storeId' | 'brandId'>[] | undefined
  submitPlatform: (platformParams: PlatformFormData) => Promise<void>
  submitStore: (store: StoreParams) => void
  submitBrand: (brand: BrandParams) => void
  submitMenu: () => void
  goBack: () => void
  loading: boolean
  error: Error | undefined
};

export const useRegistrationState = (
  operationType: OperationTypes,
  platformOperationType: PlatformOperationType,
  initialStore?: Store,
  initialBrand?: Brand
): Result => {
  const { account } = useAppContext();

  const router = useRouter();
  const [step, setStep] = useState<RegistrationStep>(initialStep(operationType));
  const [store, setStore] = useState<StoreParams>();
  const [brand, setBrand] = useState<BrandParams>();
  const [identities, setIdentities] = useState<IdentityParam[]>();
  const [menu, setMenu] = useState<Omit<Item, 'accountId' | 'storeId' | 'brandId'>[]>();
  const [executeTime, setExecuteTime] = useState<Date>();

  const [request, loading, error] = useMutationHttpApi();

  const goBack = useCallback(() => {
    setStep(step => {
      switch (step) {
        case RegistrationStep.STORE: throw new Error('There is no step before STORE');
        case RegistrationStep.BRAND: return RegistrationStep.STORE;
        case RegistrationStep.PLATFORM: return RegistrationStep.BRAND;
        case RegistrationStep.MENU: return RegistrationStep.PLATFORM;
      }
    })
  }, []);

  const submitStore = useCallback((store: StoreParams) => {
    setStore(store);
    setStep(RegistrationStep.BRAND);
  }, []);

  const submitBrand = useCallback((brand: BrandParams) => {
    setBrand(brand);
    setStep(RegistrationStep.PLATFORM);
  }, []);

  const submitPlatform = useCallback((formData: PlatformFormData) => {
    if (platformOperationType === 'registration' || platformOperationType === 'activation') {
      const identities = mapFormDataToIdentities(formData);
      setIdentities(identities);

      return request(OrderlyManagerApi.orderPlatforms.items.list(formData.type, { identities }))
        .then(({ menu }) => {
          setMenu(menu);
          setExecuteTime(new Date(formData.executeTime));
          setStep(RegistrationStep.MENU);
        })
        .catch(() => {});
    } else if (initialStore && initialBrand) {
      return request(OrderlyManagerApi.orderPlatforms.operations.create(formData.type, {
        type: platformOperationType,
        storeId: initialStore.id,
        brandId: initialBrand.id,
        executeTime: new Date(formData.executeTime).toISOString()
      }))
        .then(() => router.push(`/stores/${initialStore.id}/brands/${initialBrand.id}?platform=${formData.type}`))
        .then(() => {})
        .catch(() => {});
    } else {
      throw new Error('store or brand is missing');
    }
  }, [initialBrand, initialStore, platformOperationType, request, router]);

  const submitMenu = useCallback(async () => {
    switch (operationType) {
      case 'STORE': {
        if (!account || !brand || !store || !identities || !executeTime) return;

        const [{ store: createdStore }, { brand: createdBrand }] = await Promise.all([
          request(OrderlyManagerApi.stores.create({ ...store })).catch(() => ({ store: undefined })),
          brand?.id
            ? { brand: { ...brand, id: brand.id } }
            : request(OrderlyManagerApi.brands.create({ name: brand.name })).catch(() => ({ brand: undefined }))
        ]);

        if (!createdStore || !createdBrand) return;

        await request(OrderlyManagerApi.orderPlatforms.operations.create(identities[0].platformType, {
          type: 'registration',
          storeId: createdStore.id,
          brandId: createdBrand.id,
          executeTime: executeTime.toISOString(),
          identities
        }))
          .then(() => router.push(`/stores/${createdStore.id}/brands/${createdBrand.id}?platform=${identities[0].platformType}`))
          .catch(() => {});
        break;
      }
      case 'BRAND': {
        if (!account || !initialStore || !brand || !identities || !executeTime) return;

        const { brand: createdBrand } = brand?.id
          ? { brand: { ...brand, id: brand.id } }
          : await request(OrderlyManagerApi.brands.create({ name: brand.name })).catch(() => ({ brand: undefined }));

        if (!createdBrand) return;

        await request(OrderlyManagerApi.orderPlatforms.operations.create(identities[0].platformType, {
          type: 'registration',
          storeId: initialStore.id,
          brandId: createdBrand.id,
          executeTime: executeTime.toISOString(),
          identities
        }))
          .then(() => router.push(`/stores/${initialStore.id}/brands/${createdBrand.id}?platform=${identities[0].platformType}`))
          .catch(() => {});
        break;
      }
      case 'PLATFORM': {
        if (!account || !initialStore || !initialBrand || !identities || !executeTime) return;

        await request(OrderlyManagerApi.orderPlatforms.operations.create(identities[0].platformType, {
          type: platformOperationType,
          storeId: initialStore.id,
          brandId: initialBrand.id,
          executeTime: executeTime.toISOString(),
          identities
        }))
          .then(() => router.push(`/stores/${initialStore.id}/brands/${initialBrand.id}?platform=${identities[0].platformType}`))
          .catch(() => {});
        break;
      }
    }
  }, [account, brand, executeTime, identities, initialBrand, initialStore, operationType, platformOperationType, request, router, store]);

  return {
    allSteps: allSteps(operationType),
    step,
    store,
    brand,
    menu,
    submitPlatform,
    submitStore,
    submitBrand,
    submitMenu,
    goBack,
    loading,
    error
  };
};

const allSteps = (type: OperationTypes): RegistrationStep[] => {
  switch (type) {
    case 'STORE': return [RegistrationStep.STORE, RegistrationStep.BRAND, RegistrationStep.PLATFORM, RegistrationStep.MENU];
    case 'BRAND': return [RegistrationStep.BRAND, RegistrationStep.PLATFORM, RegistrationStep.MENU];
    case 'PLATFORM': return [RegistrationStep.PLATFORM, RegistrationStep.MENU];
  }
}

const initialStep = (type: OperationTypes): RegistrationStep => {
  switch (type) {
    case 'STORE': return RegistrationStep.STORE;
    case 'BRAND': return RegistrationStep.BRAND;
    case 'PLATFORM': return RegistrationStep.PLATFORM;
  }
}

const mapFormDataToIdentities = (formData: PlatformFormData): IdentityParam[] => {
  switch(formData.type) {
    case 'boteats':
      return [{
        platformType: 'boteats' as const,
        applicationType: 'api' as const,
        loginId: formData.email,
        password: formData.password
      } as BotEatsIdentity<HasRawPassword>];
    case 'demaecan':
      return [
        {
          platformType: 'demaecan',
          applicationType: 'appApi',
          loginId: formData.email,
          password: formData.password
        } as DemaecanIdentity<HasRawPassword>,
        {
          platformType: 'demaecan',
          applicationType: 'adminWeb',
          loginId: formData.adminId,
          password: formData.adminPassword,
          code: formData.adminCode
        } as DemaecanIdentity<HasRawPassword>
      ];
    case 'demaecanOfficial':
      return [{
        platformType: 'demaecanOfficial',
        applicationType: 'api',
        loginId: formData.uuid,
        corpId: formData.corpId,
        token: formData.token
      } as DemaecanOfficialIdentity<HasRawPassword>];
    case 'menuOfficial':
      return [{
        platformType: 'menuOfficial',
        applicationType: 'api',
        loginId: formData.uuid
      }];
    case 'menu':
      return [{
        platformType: 'menu',
        applicationType: 'appApi',
        loginId: formData.email,
        password: formData.password
      } as MenuIdentity<HasRawPassword>];
    case 'sukesan':
      return [{
        platformType: 'sukesan',
        store_code: formData.store_code,
      } as SukesanIdentity];
    case 'ubereats':
      return [{
        platformType: 'ubereats',
        applicationType: 'appApi',
        loginId: formData.email,
        password: formData.password
      } as UberEatsIdentity<HasRawPassword>];
    case 'ubereatsOfficial':
      return [{
        platformType: 'ubereatsOfficial',
        loginId: formData.store_id,
      } as UberEatsOfficialIdentity];
    case 'wolt':
      return [
        {
          platformType: 'wolt',
          applicationType: 'appApi',
          loginId: formData.email,
          password: formData.password
        } as WoltIdentity<HasRawPassword>,
        {
          platformType: 'wolt',
          applicationType: 'adminApi',
          loginId: formData.email,
          password: formData.password
        } as WoltIdentity<HasRawPassword>
      ];
    case 'woltOfficial':
      return [
        {
          platformType: 'woltOfficial',
          applicationType: 'api',
          type: 'order',
          loginId: formData.venueId,
          token: formData.token
        } as WoltOfficialIdentity<HasRawPassword>,
        {
          platformType: 'woltOfficial',
          applicationType: 'appApi',
          loginId: formData.email,
          password: formData.password
        } as WoltOfficialIdentity<HasRawPassword>,
        {
          platformType: 'woltOfficial',
          applicationType: 'adminApi',
          loginId: formData.email,
          password: formData.password
        } as WoltOfficialIdentity<HasRawPassword>
      ];
  }
}
