import { Analytics } from '../../../../entities';
import { ApiRequest } from '../../../core';
import { base } from '../../base';

type Params = {
  storeId?: string;
  startDate: string;
  endDate: string;
  group: 'hour' | 'day' | 'week' | 'month'
}

type Response = Analytics;

export const get = (params: Params): ApiRequest<Response> =>
  ({ ...base(), method: 'GET', url: '/analytics', params });
