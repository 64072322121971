import { Item } from './Item';
import { PosItem } from './PosItem';

export interface ItemPosItem {
  type: 'delivery' | 'pick_up'
  posItemId: string
  itemId: string
}

export const posItemToItemPosItems = (posItem: PosItem): ItemPosItem[] => [
  ...posItem.itemIds.map(itemId => ({ type: 'delivery' as const, posItemId: posItem.id, itemId })),
  ...(posItem.pickupItemIds?.map(itemId => ({ type: 'pick_up' as const, posItemId: posItem.id, itemId })) ?? []),
];

export const mapItemPosItemsWithItemPosItems = (posItems: PosItem[], itemPosItems: ItemPosItem[] | undefined): PosItem[] =>
  posItems.map(posItem => ({
    ...posItem,
    itemIds: itemPosItems
      ?.filter(itemPosItem => itemPosItem.type === 'delivery')
      .filter(itemPosItem => itemPosItem.posItemId === posItem.id)
      .map(itemPosItem => itemPosItem.itemId)
      ?? [],
    pickupItemIds: itemPosItems
      ?.filter(itemPosItem => itemPosItem.type === 'pick_up')
      .filter(itemPosItem => itemPosItem.posItemId === posItem.id)
      .map(itemPosItem => itemPosItem.itemId)
      ?? [],
  }));


export const buildItemPosItems = (item: Item, posItem: Pick<PosItem, 'id'>, usePickupPosItem: boolean): ItemPosItem[] =>
  usePickupPosItem
    ? [
      { type: 'delivery', posItemId: posItem.id, itemId: item.id },
      { type: 'pick_up', posItemId: posItem.id, itemId: item.id }
    ]
    : [{ type: 'delivery', posItemId: posItem.id, itemId: item.id }];
