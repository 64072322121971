import { HasRawPassword, HasTimestamp, Item, OrderPlatformIdentity, PlatformType } from '../../../../../entities';
import { ApiRequest } from '../../../../core';
import { base } from '../../../base';

type Params = {
  identities: Omit<OrderPlatformIdentity<HasRawPassword>, 'id' | 'accountId' | 'invalidatedTime' | keyof HasTimestamp>[];
}

type Response = {
  menu: Omit<Item, 'accountId' | 'storeId' | 'brandId'>[]
};

export const list = (platformType: PlatformType, data: Params): ApiRequest<Response> =>
  ({ ...base(), method: 'POST', url: `/order-platforms/${platformType}/items`, data });
