import { useCallback, useState } from 'react';
import { ApiRequest, createApiClient } from '../apis';
import { useAuthState } from './useAuthState';

type Response = [<T>(request: ApiRequest<T>) => Promise<T>, boolean, Error | undefined];

export const useMutationHttpApi = (): Response => {
  const [auth, authLoading] = useAuthState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const request = useCallback(<T>(request: ApiRequest<T>): Promise<T> => {
    if (!auth || authLoading) return Promise.reject();

    setLoading(true);

    return auth.getIdToken()
      .then(token => ({ ...request, headers: { ...request.headers, Authorization: `Bearer ${token}` } }) as ApiRequest<T>)
      .then(createApiClient().request)
      .catch(error => {
        setError(error);
        return Promise.reject(error);
      })
      .finally(() => setLoading(false));
  }, [auth, authLoading]);

  return [request, loading, error];
}
