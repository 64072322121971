import { IpadicFeatures, Tokenizer } from 'kuromoji';
import { PosItem } from '.';
import { cosSimilarity, nGram, nGramToVector, normalizeSentence, unique } from '../utils';
import { Item } from './Item';

export interface SimilarityComparable {
  id: string
  value: string
  furigana: string
}

export type Similarity  = {
  id: string
  matched: Record<string, { similarity: number }>
};

export const itemToSimilarityComparable = (item: Item, tokenizer: Tokenizer<IpadicFeatures>): SimilarityComparable  => ({
  id: item.id,
  value: item.title,
  furigana: normalizeSentence(item.title, tokenizer)
});

export const posItemToSimilarityComparable = (posItem: PosItem, tokenizer: Tokenizer<IpadicFeatures>): SimilarityComparable  => ({
  id: posItem.id,
  value: posItem.name,
  furigana: normalizeSentence(posItem.name, tokenizer)
});

export const getSimilarity =(l: SimilarityComparable, r: SimilarityComparable): number => {
  const lFuriganaNgram = nGram(l.furigana, 2);
  const rFuriganaNgram = nGram(r.furigana, 2);
  const conbinedNgram = unique(lFuriganaNgram.concat(rFuriganaNgram));
  return cosSimilarity(nGramToVector(conbinedNgram ,lFuriganaNgram), nGramToVector(conbinedNgram, rFuriganaNgram))
}

export const getSimilar = (l: SimilarityComparable, rs: SimilarityComparable[]): Similarity => ({
  id: l.id,
  matched: rs
    .reduce((acc, r) => Object.assign(acc, ({ [r.id]: { similarity: getSimilarity(l, r) } })), {})
});

export const calculateAllSimilarity = (ls: SimilarityComparable[], rs: SimilarityComparable[]): Similarity[] =>
  ls.map(l => getSimilar(l, rs));
