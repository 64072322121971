import { HasTimestamp, PosItem, PosPlatformType, PosRelation } from '../../../../../../entities';
import { ApiRequest } from '../../../../../core';
import { base } from '../../../../base';

type Params = {
  storeId: string;
  items: Omit<PosItem, keyof HasTimestamp | keyof PosRelation>[];
}

type Response = Omit<PosItem, keyof HasTimestamp | keyof PosRelation>[];

export const update = (posPlaformType: PosPlatformType, data: Params): ApiRequest<Response> =>
  ({ ...base(), method: 'PUT', url: `/pos-platforms/${posPlaformType}/items/bulk`, data });
