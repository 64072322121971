import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Delivery } from '../../entities';

export const useOrderDeliveries = (
  accountId: string | undefined,
  storeId: string | undefined,
  brandId: string | undefined,
  orderId: string | undefined
): [Delivery[] | undefined, boolean, Error | undefined] =>
  useCollectionData<Delivery>(
    accountId && storeId && brandId && orderId &&
    firebase
      .firestore()
      .collection('accounts')
      .doc(accountId)
      .collection('stores')
      .doc(storeId)
      .collection('brands')
      .doc(brandId)
      .collection('deliveries')
      .where('orderId', '==', orderId),
    { idField: 'id' },
  )
