import firebase from 'firebase';
import { useState } from 'react';
import { hasLocalizedDescription, HasLocalizedDescription } from '../../entities';

export type CallableResult<Request, Response> = {
  loading: boolean,
  error: Error | undefined,
  call: (params: Request) => Promise<Response>
};

export const useCallable = <Request, Response>(name: string, options?: firebase.functions.HttpsCallableOptions): CallableResult<Request, Response> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const call = (params: Request): Promise<Response> => {
    setLoading(true);
    return firebase.app().functions('asia-northeast1').httpsCallable(name, options)(params)
      .then(result => result.data)
      .catch(error => setError(mapError(error)))
      .finally(() => { setLoading(false); });
  };

  return { loading, error, call };
};

const mapError = (error: unknown): HasLocalizedDescription & Error => {
  if (error instanceof Error && hasDetails(error) && hasLocalizedDescription(error.details))
    return { ...error, ...error.details };

  if (error instanceof Error)
    return { ...error, localizedTitle: error.message, localizedDetail: '' };

  return {
    name: 'unknown-error',
    message: '不明なエラーが発生しました。',
    localizedTitle: '不明なエラーが発生しました。',
    localizedDetail: '不明なエラーが発生しました。時間をおいてもう一度試すか、それでも解決しない場合はサポートにご連絡ください。',
  };
};

const hasDetails = (error: unknown): error is { details: unknown } =>
  typeof error === 'object' && !!error && 'details' in error;
