import { ApiRequest } from '../core/client';

export type OrderlyManagerApiRequestBase = Pick<ApiRequest<void>, 'baseURL' | 'headers' | 'apiName' | 'authenticationErrorStatusCodes'>

const host = (() => {
  switch(process.env.FIREBASE_PROJECT_ID) {
    case 'foodstudio-dev2': return 'development-manager-api.orderly.jp'
    case 'foodstudio-stg': return 'staging-manager-api.orderly.jp'
    case 'foodstudio-prod': return 'manager-api.orderly.jp'
    default: return 'development-manager-api.orderly.jp'
  }
})();

export const base = (): OrderlyManagerApiRequestBase => ({
  baseURL: `https://${host}/api/manager`,
  apiName: 'OrderlyManagerApi',
  authenticationErrorStatusCodes: [401, 403]
});
