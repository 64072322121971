import axios, { AxiosError, AxiosResponse } from 'axios';
import { HttpAuthenticationError, HttpClientError, HttpError, HttpServerError } from '../../errors';

type ResponseInterceptor = [
  (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
  (error: AxiosError) => Promise<unknown> | undefined
];

type Params = {
  mapResponseToError?: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
  mapErrorResponseToError?: (response: AxiosResponse) => HttpError | undefined
  authenticationErrorStatusCodes: number[]
};

export const errorMapInterceptor = (
  { mapResponseToError, mapErrorResponseToError, authenticationErrorStatusCodes }: Params
): ResponseInterceptor =>
  [
    response => mapResponseToError ? mapResponseToError(response) : response,
    error => Promise.reject(mapError(error, authenticationErrorStatusCodes, mapErrorResponseToError))
  ];

const mapError = (
  error: Error,
  authenticationErrorCodes: number[],
  mapErrorResponse?: (response: AxiosResponse) => HttpError | undefined
): Error => {
  if (!axios.isAxiosError(error)) return error;
  if (!error.response) return error;

  const mappedError = mapErrorResponse?.(error.response);

  if (mappedError)
    return mappedError;

  if (authenticationErrorCodes.includes(error.response.status))
    return new HttpAuthenticationError(error.config, error.response);

  if (error.response.status >= 400 && error.response.status < 500)
    return new HttpClientError(error.config, error.response);

  if (error.response.status >= 500 && error.response.status < 600)
    return new HttpServerError(error.config, error.response);

  return error;
};
