import { ReportType } from '../../../../entities';
import { ApiRequest } from '../../../core';
import { base } from '../../base';

type Params = {
  storeIds: string[] | null;
  type: ReportType;
  startTime: number;
  endTime: number;
}

export const create = (data: Params): ApiRequest<void> =>
  ({ ...base(), method: 'POST', url: '/reports', data });
