import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { pickObject } from '../../../utils';

export class HttpAuthenticationError extends Error {
  readonly config: Pick<AxiosRequestConfig, 'method' | 'baseURL' | 'url' | 'headers' | 'params' | 'data'> | undefined;
  readonly response: Pick<AxiosResponse, 'status' | 'statusText' | 'headers' | 'data'>;

  constructor(config: AxiosRequestConfig | undefined, response: AxiosResponse) {
    const trueProto = new.target.prototype;
    super(`Received http authentication error response: ${response.statusText} ${config?.method?.toUpperCase()} ${config?.baseURL}${config?.url}`);
    this.name = new.target.name;
    Object.setPrototypeOf(this, trueProto);

    this.config = config && pickObject(config, ['method', 'baseURL', 'url', 'headers', 'params', 'data']);
    this.response = pickObject(response, ['status', 'statusText', 'headers', 'data']);
  }
}
