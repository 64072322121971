import { Brand, Platform, PlatformType, Store } from '../../entities';
import { useAppContext } from '../../hooks';
import { useSelectableResource } from './useSelectableResource';

interface Response {
  store: Store | undefined;
  storeBrands: Brand[];
  brandPlatforms: Platform[];
  selectedBrand: Brand | undefined;
  selectedPlatform: Platform | undefined;
  selectBrandId: (brandId: string) => void;
  selectPlatformType: (platformType: PlatformType) => void;
}

export const useSelectableStoreResources = (storeId: string, allPlatforms: Platform[] | undefined): Response => {
  const { stores, brands } = useAppContext();

  const store = stores?.find(store => store.id === storeId);

  const storeBrands = brands?.filter(brand => !!allPlatforms?.map(platform => platform.brandId).includes(brand.id)) ?? [];
  const { selected: selectedBrand, select: selectBrandId } = useSelectableResource(storeBrands);

  const brandPlatforms = allPlatforms?.filter(platform => platform.brandId === selectedBrand?.id) ?? [];
  const { selected: selectedPlatform, select: selectPlatformType } = useSelectableResource(brandPlatforms);

  return {
    store,
    storeBrands,
    brandPlatforms,
    selectedBrand,
    selectedPlatform,
    selectBrandId,
    selectPlatformType
  };
}
