import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { errorMapInterceptor, HttpError } from './index';

export interface ApiRequest<Response> extends AxiosRequestConfig {
  apiName: string
  authenticationErrorStatusCodes: number[]
  mapResponseToError?: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
  mapErrorResponseToError?: (response: AxiosResponse) => HttpError | undefined
}

export type ApiClient = {
  requestRaw: <Response>(apiRequest: ApiRequest<Response>) => AxiosPromise<Response>
  request: <Response>(apiRequest: ApiRequest<Response>) => Promise<Response>
};

export const createApiClient = (): ApiClient => {
  const requestRaw = <Response>(apiRequest: ApiRequest<Response>): AxiosPromise<Response> => {
    const instance = axios.create();

    instance.interceptors.response.use(errorMapInterceptor(apiRequest)[0], errorMapInterceptor(apiRequest)[1]);

    return instance.request(apiRequest);
  };

  const request = <Response>(apiRequest: ApiRequest<Response>): Promise<Response> =>
    requestRaw(apiRequest).then(response => response.data);

  return { request, requestRaw };
};
