import { HasRawPassword, HasTimestamp, PosPlatformIdentity, PosPlatformType } from '../../../../../entities';
import { ApiRequest } from '../../../../core';
import { base } from '../../../base';

type Params = {
  type: 'registration';
  storeId: string;
  identity: Omit<PosPlatformIdentity<HasRawPassword>, 'id' | 'accountId' | 'invalidatedTime' | keyof HasTimestamp>;
}

export const create = (posType: PosPlatformType, data: Params): ApiRequest<void> =>
  ({ ...base(), method: 'POST', url: `pos-platforms/${posType}/operations`, data });
