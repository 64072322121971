import { PlatformType } from '../../../../../entities';
import { ApiRequest } from '../../../../core';
import { base } from '../../../base';

type Params = {
  storeId: string;
  brandId: string;
}

export const destroy = (platformType: PlatformType, id: string, data: Params): ApiRequest<void> =>
  ({ ...base(), method: 'DELETE', url: `/order-platforms/${platformType}/operations/${id}`, data });
