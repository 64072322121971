import { Store, } from '../../../../entities';
import { ApiRequest } from '../../../core';
import { base } from '../../base';

type Params = {
  name: string;
  email: string;
}

type Response = {
  store: Pick<Store, 'id'>
}

export const create = (data: Params): ApiRequest<Response> =>
  ({ ...base(), method: 'POST', url: '/stores', data })
