import { HasLocalizedDescription } from './HasLocalizedDescription';

export type Rfc7807Compliant = {
  type: string
  status: number
  title: string
  detail: string
};

export type LocalizableRfc7807Compliant = HasLocalizedDescription & Rfc7807Compliant;

export const isLocalizableRfc7807Compliant = (value: unknown): value is LocalizableRfc7807Compliant =>
  typeof value === 'object'
  && !!value
  && 'type' in value
  && 'status' in value
  && 'title' in value
  && 'localizedTitle' in value
  && 'detail' in value
  && 'localizedDetail' in value;
