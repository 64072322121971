import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Platform } from '../../entities';

export const usePlatforms = (
  accountId: string | undefined,
  storeId: string | undefined,
  brandId: string | undefined
): [Platform[] | undefined, boolean, Error | undefined] =>
  useCollectionData<Platform>(
    accountId && storeId && brandId &&
      firebase
        .firestore()
        .collection('accounts')
        .doc(accountId)
        .collection('stores')
        .doc(storeId)
        .collection('brands')
        .doc(brandId)
        .collection('platforms'),
    { idField: 'id' },
  );
