import firebase from 'firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Report } from '../../entities';

export const useReports = (accountId: string | undefined): [Report[] | undefined, boolean, Error | undefined] =>
  useCollectionData<Report>(
    accountId && firebase.firestore()
      .collection('accounts')
      .doc(accountId)
      .collection('reports')
      .orderBy('createTime', 'desc'),
    { idField: 'id' },
  );
